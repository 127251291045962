import { render, staticRenderFns } from "./LegalInfo.vue?vue&type=template&id=32045bc6&scoped=true&"
import script from "./LegalInfo.vue?vue&type=script&lang=ts&"
export * from "./LegalInfo.vue?vue&type=script&lang=ts&"
import style0 from "./LegalInfo.vue?vue&type=style&index=0&id=32045bc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32045bc6",
  null
  
)

export default component.exports