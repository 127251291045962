<template>
  <div class="home">
    
    <div class="home-holder">
      <div class="home__logo">
        <Logo /> 
      </div>
      <h1 class="home__header">
        Już wkrótce
      </h1>
      <div class="home__legalinfo">
        <LegalInfo/>
      </div>
    </div>
    
  </div>
</template>

<script>
import Logo from '@/components/atoms/Logo'
import LegalInfo from '@/components/atoms/LegalInfo.vue'

export default {
  name: 'Home',
  components: { Logo, LegalInfo }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap');

.home {
  min-width: 400px;
  width: 100%;
  height: 100vh;

  font-family: 'Inter', sans-serif;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  &__logo {
    background-color: #EAE9FF;
    padding: 16px 120px;
    border-radius: 20px;
  }

  &__legalinfo {
    padding: 16px 120px;
    max-width: 700px;
    border-radius: 20px;
  }
}

.home-holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.home__header {
  font-size: 28px;
}

.home__legalinfo {
  font-size: 12px;
}

@media (max-width: 768px) {
  .home__logo {
    border-radius: 15px;
    padding: 5px 40px;
  } 
  .home__header {
    font-size: 24px;
  }

  .home__legalinfo {
    font-size: 12px;
  }
}
</style>
